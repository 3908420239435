@import "~@homehero/hero-style/dist/styles/settings/colors";
@import "~@homehero/hero-style/dist/styles/settings/spaces";
@import "~@homehero/hero-style/dist/styles/settings/radius";
@import "~@homehero/hero-style/dist/styles/tools/mq";

.c-gallery {
  display: flex;

  &--small {
    text-align: center;
  }

  &--unique {
    display: block;
    height: 120px;
    max-width: 120px;
  }
}
