@import "~@homehero/hero-style/dist/styles/settings/spaces";
@import "~@homehero/hero-style/dist/styles/settings/colors";
@import "~@homehero/hero-style/dist/styles/settings/radius";
@import "~@homehero/hero-style/dist/styles/settings/types";

.notes-list-header {
  padding: 0 $space-3;
  height: 50px;
  border-bottom: solid 1px $color-neutral-90;
  display: flex;
  align-items: center;
  justify-content: space-between;

  h3 {
    font-size: 18px;
    font-family: $font-family;
  }
}
