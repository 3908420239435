@import '~@homehero/hero-style/dist/styles/tools/mq';
@import '~@homehero/hero-style/dist/styles/settings/spaces';
@import '~@homehero/hero-style/dist/styles/settings/colors';
@import '~@homehero/hero-style/dist/styles/settings/radius';
@import '~@homehero/hero-style/dist/styles/settings/types';

.c-btn {
  background-color: Transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  padding: 0px;
  margin-left: 4px;
  margin-right: 4px;
}

.c-mobile-header {
  background-color: white;
  padding: $space-2 $space-2;
  display: flex;
  align-items: center;
  width: 100%;
  text-align: center;
  font-weight: 500;
  p {
    flex: 1;
  }
  &__notifications {
    padding: 0 $space-1;
  }

  @include mq($from: tablet) {
    display: none;
  }
}
