@import "~@homehero/hero-style/dist/styles/settings/spaces";
@import "~@homehero/hero-style/dist/styles/settings/colors";
@import "~@homehero/hero-style/dist/styles/settings/types";

.time-picker-popup {
          .ant-picker-time-panel-column {
            &::-webkit-scrollbar {
              display: none;
            }
            &:hover {
              &::-webkit-scrollbar {
                display: block;
                width: 4px;
              }

              &::-webkit-scrollbar-track {
                -webkit-border-radius: 8px;
                border-radius: 8px;
              }

              &::-webkit-scrollbar-thumb {
                -webkit-border-radius: 8px;
                border-radius: 8px;
                background: $color-brand-primary-40;
              }
            }
          }
        }
