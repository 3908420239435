@import "~@homehero/hero-style/dist/styles/settings/colors";
@import "~@homehero/hero-style/dist/styles/settings/spaces";
@import "~@homehero/hero-style/dist/styles/settings/shadows";
@import "~@homehero/hero-style/dist/styles/settings/radius";
@import "~@homehero/hero-style/dist/styles/tools/mq";

.c-box {
  background-color: $color-white;
  padding: $space-3;
  border-radius: $radius-2;
  border: solid 1px $color-white;
  position: relative;

  & + .c-box {
    margin-top: $space-2;
  }

  @include mq($until: tablet) {
    padding: $space-2;
  }

  @include mq($until: tablet) {
    margin-top: $space-1;
  }

  &--elevation-1 {
    box-shadow: $elevation-0;
  }

  &--elevation-2 {
    box-shadow: $elevation-1;
  }

  &--elevation-3 {
    box-shadow: $elevation-2;
  }

  &--elevation-4 {
    box-shadow: $elevation-3;
  }

  &--elevation-5 {
    box-shadow: $elevation-4;
  }

  &--margin-bottom-1 {
    margin-bottom: $space-1;
  }

  &--margin-bottom-2 {
    margin-bottom: $space-2;
  }

  &--margin-bottom-3 {
    margin-bottom: $space-3;
  }

  &--margin-bottom-4 {
    margin-bottom: $space-4;
  }
}
