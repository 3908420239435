
.c-align {
  text-align: left;

  &--center {
    text-align: center;
  }

  &--right {
    text-align: right;
  }

  &--between {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
