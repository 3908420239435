@import "~@homehero/hero-style/dist/styles/settings/colors";
@import "~@homehero/hero-style/dist/styles/settings/spaces";
@import "~@homehero/hero-style/dist/styles/settings/radius";
@import "~@homehero/hero-style/dist/styles/tools/mq";

.c-gallery-item {
  position: relative;
  width: 160px;
  min-width: 160px;
  height: 150px;
  margin: 8px;

  &--unique {
    display: block;
    height: 120px;
    max-width: 120px;
  }

  &--small {
    width: 80px;
    min-width: 80px;
    height: 80px;
  }

  &__img {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: $radius-2;
  }

  button {
    color: $color-white;

    & + span {
      margin-top: $space-1;
    }
  }

  a + button {
    margin-left: $space-0;
  }

  span {
    display: flex;
  }

  &__hover,
  &__bg {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    opacity: 0;
    transition: all .3s ease-in-out;
  }

  &__bg {
    background-color: $color-neutral-20;
    border-radius: $radius-2;
  }

  &__hover {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: $space-0;
    flex-direction: column;
  }

  &:hover {
    .c-gallery-item__bg {
      opacity: .82;
    }

    .c-gallery-item__hover {
      opacity: 1;
    }
  }

  @include mq($until: desktop) {
    height: 90px;
  }
}
