@use "~@homehero/hero-style/dist/styles/settings/colors";
@use "~@homehero/hero-style/dist/styles/settings/types";
@use "~@homehero/hero-style/dist/styles/settings/spaces";
@import "~@homehero/hero-style/dist/styles/tools/mq";

.c-attachment-grid {
  @include mq($until: tablet) {
    display: flex;
    justify-content: space-between;
  }
}

.c-attachment-flex {
  display: flex;
}

.c-attachment {
  &--header {
    margin-top: spaces.$space-2;
    margin-bottom: spaces.$space-2;
  }

  &--list {
    margin-bottom: spaces.$space-2;
    font-size: types.$font-size-sm;
    @include mq($until: tablet) {
      margin-top: spaces.$space-2;
    }
  }

  &--action {
    align-self: center;
    padding-left: spaces.$space-1;

    svg {
      color: colors.$color-danger;
    }
  }

  &--vertical-center {
    align-self: center;
  }

  :global {
    .FileInput-module_c-file__empty__1GLK2 {
      font-size: types.$font-size-sm;
      padding: spaces.$space-1;

      svg {
        width: 0.8em;
      }
    }

    .FileCard_c-file-card__icon__2x7Qn {
      height: 24px;
    }
  }
}
