@import "~@homehero/hero-style/dist/styles/settings/colors";
@import "~@homehero/hero-style/dist/styles/settings/spaces";
@import "~@homehero/hero-style/dist/styles/settings/types";
@import "~@homehero/hero-style/dist/styles/tools/mq";
@import "../../styles/variables";

.c-file-comments {
  overflow: auto;
  height: calc(100% - 75px);

  &::-webkit-scrollbar {
      display: none;
    }

  &:hover {
    &::-webkit-scrollbar {
      display: block !important;
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      -webkit-border-radius: 8px;
      border-radius: 8px;
      background: $color-neutral-secondary-70;
    }

    &::-webkit-scrollbar-thumb {
      -webkit-border-radius: 8px;
      border-radius: 8px;
      background: $color-brand-primary-40;
    }
  }
}

.c-no-comments {
  display:  flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: $color-neutral-60;
  margin-top: $space-2;

  p {
    margin-top: $space-1;
    width: 65%;
    text-align: center;
  }

  svg {
    font-size: $font-size-md * 2;
  }
}

.drawer{
  max-height: calc(100vh - 220px);

 .action{
  bottom: 50px;
  padding: $space-2 $space-5 $space-2 $space-3;
 } 
}

.action{
  position: absolute;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  padding: $space-2;
  width: 100%;
}
