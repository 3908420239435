@import "~@homehero/hero-style/dist/styles/settings/spaces";
@import "~@homehero/hero-style/dist/styles/settings/types";
@import "~@homehero/hero-style/dist/styles/settings/colors";

.c-filter-range {
  width: 209px;
  font-size: $font-size-md;

  span {
    color: $color-neutral-60;
    display: block;
    font-weight: 100;
  }

  &__inputs {
    display: flex;
    align-items: center;

    span {
      padding: 0 $space-1;
    }

    svg {
      top: 11px;
      left: 12px
    }

    input {
      padding-left: 28px !important;
    }
  }
}
