@import "~@homehero/hero-style/dist/styles/settings/spaces";
@import "~@homehero/hero-style/dist/styles/settings/radius";
@import "~@homehero/hero-style/dist/styles/settings/types";
@import "~@homehero/hero-style/dist/styles/settings/colors";
@import "~@homehero/hero-style/dist/styles/tools/mq";

.c-file-card {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding: $space-3;
  height: 160px;
  width: 168px;
  cursor: pointer;
  border: 1px solid $color-neutral-90;
  box-shadow: none;
  transition: all .3s ease-in-out;
  text-align: center;

  @include mq($until: tablet) {
    min-height: 124px;
  }

  &:hover {
    border: 1px solid $color-brand-primary-60;

    button{
      display: flex;
    }
  }

  &--selected {
    background-color: $color-brand-primary-95;
    border: 1px solid $color-brand-primary-60;
  }

  &__icon {
    color: $color-primary;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    position: relative;

    button{
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      bottom: 4px;
      right: 4px;
      height: 24px;
      width: 24px;
      border-radius: 50%;
      background-color: $color-primary;
      color: #fff;
      border: 0;

      display: none;
      cursor: pointer;
      transition: 0.2s;

      :hover {
        filter: brightness(0.8);
      }
    }

    .file-image {
      width: 100%;
      height: 100%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      border-radius: $radius-2;
    }
  }

  &__text {
    width: 100%;
    padding-top: 8px;
    text-align: left;
    p {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}
