@import '../../styles/variables';
@import '~@homehero/hero-style/dist/styles/settings/colors';
@import '~@homehero/hero-style/dist/styles/settings/types';
@import '~@homehero/hero-style/dist/styles/settings/spaces';
@import '~@homehero/hero-style/dist/styles/tools/mq';

.main-header {
  background-color: $color-white;
  color: $color-neutral-50;
  transition: padding-left 0.4s $cubic-bezier;

  header {
    padding: 0 $space-3;
    border-bottom: 1px solid $color-neutral-90;

    @include mq($until: tablet) {
      padding: 0;
    }
  }

  &--collapse {
    padding-left: $nav-width--collapsed;

    @include mq($until: tablet) {
      padding-left: 0;
    }
  }

  &__heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    min-height: $space-8;

    @include mq($until: tablet) {
      align-items: flex-start;
      display: none;
      padding: 0;

      &.force-show-mobile {
        display: flex;
      }
    }

    h2 {
      font-size: $font-size-lg;
      color: $color-neutral-30;
      flex-grow: 10;

      @include mq($until: tablet) {
        display: none;
      }
    }
  }

  &__content {
    margin-top: $space-2;
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-left: $space-2;
    flex-grow: 1;
    text-align: right;

    &--select {
      width: 20%;
      margin: 0 $space-2;
      text-align: left;
    }
  }

  @include mq($until: tablet) {
    &__content {
      margin-top: 0;
      padding: $space-2;
    }
    &__actions {
      padding: $space-1;
    }
    &__heading {
      display: none;
    }
  }

  &--isProfile {
    height: 60px;

    @include mq($until: tablet) {
      height: unset;
    }

    header {
      display: flex;
      height: 100%;
    }
  }
}

.btn {
  cursor: pointer;
  background-color: transparent;
  border: none;
  padding: 0;

  margin-right: $space-3;
  color: $color-neutral-30;
  font-size: $font-size-lg + 2px;

  @include mq($until: tablet) {
    margin-right: 0;
    display: none;
  }
}
