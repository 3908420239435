@import "../../styles/variables";
@import "~@homehero/hero-style/dist/styles/settings/colors";
@import "~@homehero/hero-style/dist/styles/settings/types";
@import "~@homehero/hero-style/dist/styles/settings/spaces";
@import "~@homehero/hero-style/dist/styles/tools/mq";


.header {
  position: relative;
  background-color: $color-white;
  color: $color-neutral-50;

  h6 {
    font-weight: 400;
    color: $color-neutral-60;
  }

  &-dropdown{
    display: flex;
    align-items: center;
    button{
        margin-left: 8px;
    }
  }
  header {
    padding: $space-1 $space-2;
    @include mq($until: tablet) {
      padding: 0;
    }
  }
  transition: padding-left .4s $cubic-bezier;
  &--collapse {
    padding-left: $nav-width--collapsed;
    @include mq($until: tablet){
      padding-left: 0;
    }
  }

  &_heading {
    display: flex;
    align-items: center;
    width: 100%;

    &--padding-top {
      padding-top: $space-1;
    }

    &--justify-between {
      display: flex;
      justify-content: space-between;
    }

    @include mq($until: tablet) {
      align-items: flex-start;
      display: none;
      padding: 0;

      &.force-show-mobile {
        display: flex;
      }
    }

    h2 {
      font-size: $font-size-lg;
      color: $color-neutral-30;

      @include mq($until: tablet) {
        display: none;
      }
    }
  }

  &-actions {
    flex: 1;
  }

  .subTitle {
    display: flex;
    margin-left: -10px;
    align-items: center;
  }

  @include mq($until: tablet) {
    &-content {
      margin-top: 0;
      padding: $space-1;
    }
    &-actions {
      padding: $space-1;
    }
    &-heading {
      display: none;
    }
  }

}
