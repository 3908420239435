@import  '~@homehero/hero-style/dist/styles/settings/spaces';
@import  '~@homehero/hero-style/dist/styles/settings/types';
@import  '~@homehero/hero-style/dist/styles/settings/colors';

.c-product-project {
  height: 400px;
  width: 700px;

  form {
    height: 100%;
  }

  &__data {
    position: relative;

    &__select {
      div {
        z-index: 100;
        position: relative;
        align-self: baseline;
      }
    }

    &__bottom {
      position: absolute;
      bottom: 0;
      width: 100%;
    }
  }

  :global {
    .c-select--with-icon__menu-list {
      max-height: 200px;
    }

    .select-default__menu-list {
      overflow-x: visible;
      max-height: 200px;
    }
  }

  .c-product-card {
    display: flex;
    flex-direction: column;
    padding: $space-2;
    box-shadow: none;
    cursor: pointer;
    height: 100%;
    max-width: 100%;

    &__picture {
      img {
        max-width: 100%;
        height: 120px;
        align-self: center;
        padding-right: $space-2;
      }
    }

    &__description {
      text-align: left;
      padding: $space-2 0;

      div {
        display: flex !important;
      }
    }

    &__price {
      display: flex;
      color: $color-neutral-30;
      text-align: left;
      font-weight: 500;
      line-height: normal;
    }

    &__title {
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;

      font-size: $font-size-md;
      font-family: $font-family;
      color: $color-neutral-30;
    }

    &__brand {
      font-weight: 500;
      font-size: $font-size-sm;
    }
  }
}
