@import '../styles/variables';
@import '~@homehero/hero-style/dist/styles/settings/spaces';
@import '~@homehero/hero-style/dist/styles/settings/radius';
@import '~@homehero/hero-style/dist/styles/settings/types';
@import '~@homehero/hero-style/dist/styles/settings/colors';
@import '~@homehero/hero-style/dist/styles/tools/mq';

.c-main {
  min-height: calc(100% - 78px);
  transition: padding-left 0.4s $cubic-bezier;
  position: relative;
  height: 100%;
  @include mq($until: tablet) {
    position: initial;
  }

  &--collapse {
    padding-left: $nav-width--collapsed;

    @include mq($until: tablet) {
      padding-left: 0;
    }
  }

  @include mq($until: tablet) {
    margin-left: 0;
    padding-bottom: 300px;
  }
}

.c-mail-router {
  min-height: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  @include mq($until: tablet) {
    position: relative;
    background-color: $color-neutral-95;
  }
}
