@import "~@homehero/hero-style/dist/styles/settings/colors";
@import "~@homehero/hero-style/dist/styles/settings/spaces";
@import "~@homehero/hero-style/dist/styles/settings/radius";
@import "~@homehero/hero-style/dist/styles/settings/types";

.tag {
  padding: $space-0 $space-0 $space-0 $space-2;
  border-radius: $radius-4;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  color: $color-white;
  background-color: $color-primary;
  font-size: $font-size-sm;
  margin-bottom: $space-1;
  margin-right: $space-1;
  font-weight: 100;

  button {
    margin-left: $space-2;
    border: none;
    background-color: transparent;
    border-radius: $radius-5;
    padding: 0;
    height: $space-2;
    width: $space-2;
    color: $color-white;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: $font-size-sm;
  }
}

.simple-tag {
  padding: $space-1;
  font-size: $font-size-xs;
  text-align: center;
  display: inline-block;
  border-radius: $radius-1;
  font-weight: 600;
  width: 100%;
  margin-right: $space-1;

  @media (max-width: 463px) {
    position: absolute;
    top: 8px;
    right: 8px;
    width: 16px;
    height: 16px;
  }

  &--grayLight {
    color: $color-neutral-50;
    background-color: $color-neutral-90;
  }

  &--primary {
    color: $color-primary;
    background-color: $color-brand-primary-80;
  }

  &--success {
    color: $color-secondary;
    background-color: $color-brand-secondary-95;
  }

  &--danger {
    color: $color-warning-secondary-40;
    background-color: $color-warning-secondary-50;
  }

  &--warning {
    color: $color-warning-secondary-60;
    background-color: $color-warning-secondary-80;
  }
}
