@import "~@homehero/hero-style/dist/styles/settings/spaces";
@import "~@homehero/hero-style/dist/styles/settings/colors";
@import "~@homehero/hero-style/dist/styles/settings/radius";
@import "~@homehero/hero-style/dist/styles/settings/types";
@import "~@homehero/hero-style/dist/styles/tools/mq";

.c-no-content {
  display: flex;
  align-items: center;
  padding: $space-6 0;
  background-color: $color-white;
  border-radius: $radius-2;
  justify-content: center;

  @include mq($until: tablet) {
    flex-direction: column;
    padding: $space-2 0;
    text-align: center;
  }

  &__image {
    margin-right: $space-6;
    @include mq($until: tablet) {
      margin: 0 auto;
    }

    img {
      max-width: 300px;
    }
  }

  &__info,
  &__image {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__image,
  &__content {
    @include mq($until: tablet) {
      width: 100%;
    }
  }

  &__info {
    flex-direction: column;
    text-align: center;

    @include mq($until: tablet) {
      flex-direction: column;
      width: 100%;
    }
  }

  &__icon {
    color: $color-primary;
  }

  &__content {
    @include mq($until: tablet) {
      margin-top: $space-2;
      padding: 0 $space-2;
    }
    display: flex;
    flex-direction: column;

    button {
      margin-top: $space-2;

    }
  }

  &__extra-info {
    margin-top: $space-7;

    @include mq($until: tablet) {
      margin-top: $space-4;
    }
  }

  h5, p {
    font-weight: 400;
  }

  p {
    margin-top: $space-1;
    width: 400px;
    white-space: pre-line;
    @include mq($until: tablet) {
      width: 100%;
    }
  }
}
