@import  '~@homehero/hero-style/dist/styles/settings/spaces';
@import  '~@homehero/hero-style/dist/styles/settings/colors';
@import  '~@homehero/hero-style/dist/styles/settings/radius';
@import  '~@homehero/hero-style/dist/styles/settings/shadows';
@import  '~@homehero/hero-style/dist/styles/tools/mq';

.filter-button {
  position: relative;
  display: inline-block;
  margin-right: $space-1;

  button {
    padding: $space-1 $space-2;
    font-weight: 600;
    color: $color-neutral-30;

    svg {
      margin-left: $space-0;
    }
  }

  &--outline {
    button {
      padding: $space-1 $space-0;
    }
  }
}
