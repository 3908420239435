@import  '~@homehero/hero-style/dist/styles/settings/spaces';
@import  '~@homehero/hero-style/dist/styles/settings/radius';
@import  '~@homehero/hero-style/dist/styles/settings/types';
@import  '~@homehero/hero-style/dist/styles/settings/colors';
@import  '~@homehero/hero-style/dist/styles/tools/mq';

.c-product-card {
  display: flex;
  flex-direction: column;
  padding: $space-2;
  box-shadow: none;
  cursor: pointer;
  height: 100%;
  max-width: 100%;

  &--border {
    border: 1px solid $color-neutral-70;
  }

  &__picture {
    text-align: center;

    div {
      width: 100%;
    }

    img {
      max-width: 100%;
      height: 90px;
      align-self: center;
    }

    &--lg {
      img {
        height: 184px;
      }
    }
  }

  &__description {
    text-align: left;
    padding: $space-2 0;
    div {
      display: flex !important;
      min-height: 54px;
    }
  }

  &__price {
    display: flex;
    color: $color-neutral-30;
    text-align: left;
    font-weight: 500;
    line-height: normal;
  }

  &__title {
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;

    font-size: $font-size-md;
    font-family: $font-family;
    color: $color-neutral-30;
  }

  &__store,
  &__supplier
   {
    font-weight: 500;
    font-size: $font-size-sm;
  }

  &__add-btn {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 0.5em;

    svg {
      margin: $space-1 $space-2;
    }
  }
}

.c-product-card-unit {
  display: flex;
  flex-direction: column;
  padding: $space-1;
  box-shadow: none;
  height: 100%;
  max-width: 100%;

  &--border {
    border: 1px solid $color-neutral-70;
  }

  &--no-click {
    a {
      cursor: not-allowed;
    }
  }

  &__picture {
    img {
      max-width: 100%;
      height: 168px;
      align-self: center;
    }
  }

  &__description {
    text-align: left;
    padding: $space-1 0;
    div {
      display: flex !important;
    }
  }

  &__price {
    display: flex;
    height: 100%;
    color: $color-neutral-30;
    text-align: left;
    font-weight: 500;
    line-height: normal;
  }

  &__title {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-transform: uppercase;
    max-width: 12.35em;
    font-size: $font-size-sm;
    font-family: $font-family;
    color: $color-neutral-30;
  }

  &__store {
    display: none;
  }

  &__supplier {
    font-weight: 500;
    font-size: $font-size-sm;
  }

  &__add-btn {
    position: absolute;
    top: 0;
    right: 0;

    svg {
      margin: $space-1;
    }
  }
}
