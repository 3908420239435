@use  '~@homehero/hero-style/dist/styles/settings/colors' as *;
@import "~@homehero/hero-style/dist/styles/tools/mq";

.table-heading {
    display: flex;
    align-items: center;
    min-height: 48px;
    width: 100%;
    background-color: #f1f3f9;
    border-radius: 8px 8px 0 0;
    justify-content: space-between;
    cursor: pointer;

    @include mq($until: tablet) {
      background-color: #ffffff;
      border-radius: 8px;
      margin-top: 8px;
    }

  .table-heading-actions {
    margin: 1em;
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: space-around;
  }
}


