@import "~@homehero/hero-style/dist/styles/settings/spaces";
@import "~@homehero/hero-style/dist/styles/settings/colors";
@import "~@homehero/hero-style/dist/styles/tools/mq";

.c-preview-modal {
  &__content {
    width: calc(100% - 300px);
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 16px;
    background-color: $color-neutral-90;
    @include mq($until: tablet) {
      width: 100%;
    }

  }

  &__file {
    height: 100%;
    width: 100%;
    padding: 0 16px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

  }

  &__button {
    flex-direction: row;
    position: fixed;
    display: flex;
    bottom: 50px;
    justify-content: space-evenly;
    width: 400px;
    :hover{
      background-color: $color-brand-primary-70;
    }
    button {
      display: flex;
      align-items: center;
      justify-content: center;
      color: $color-brand-primary-40;
      background-color: $color-brand-primary-80;
      font-size:16px ;
    }

  }


  .c-unavailable-function {
    padding: $space-4;
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: $color-white;

    height: 100%;

    p, button {
      align-self: flex-start;
      font-weight: normal;
    }

    p {
      margin-bottom: $space-2;

      &:nth-of-type(1) {
        margin-top: $space-5;
      }
    }
  }
}
