@use "~@homehero/hero-style/dist/styles/settings/spaces";
@use "~@homehero/hero-style/dist/styles/settings/colors";
@use "~@homehero/hero-style/dist/styles/tools/mq";

.notes {
  display: flex;
  height: calc(100vh - 215px);
  background: white;

  :global {
    .tox-tinymce {
      border: none !important;
      border-radius: 0 spaces.$space-1 spaces.$space-1 0 !important;
    }
  }

  &__editor {
    width: 100%;

    .trash {
      position: absolute;
      top: spaces.$space-2;
      right: spaces.$space-3;
      cursor: pointer;
    }

    form {
      height: 100%;
      position: relative;
    }

    @include mq.mq($until: tablet) {
      padding-left: 0;
      padding-top: spaces.$space-2;
    }
  }

  @include mq.mq($until: tablet) {
    flex-direction: column;
  }
}
