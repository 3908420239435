@use '~@homehero/hero-style/dist/styles/settings/spaces';
@use "~@homehero/hero-style/dist/styles/tools/mq";

@import '~@homehero/hero-style/dist/styles/settings/colors';
@import '~@homehero/hero-style/dist/styles/settings/radius';
@import '~@homehero/hero-style/dist/styles/settings/types';
@import '~@homehero/hero-style/dist/styles/settings/opacity';

html, body, #root {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
}

p {
  line-height: spaces.$space-2;
}

#root {
  width: 100vw;

  @include mq.mq($until: tablet) {
    overflow: hidden;
  }
}

.centered {
  margin: auto;
  width: fit-content;
  padding-top: spaces.$space-5;
}

.newBtn {
  text-align: right;
  min-width: fit-content;
  .Button-module_c-btn__1xMYr {
    padding: spaces.$space-1 12px;
  }
}

.hideMobile {
  @include mq.mq($until: desktop) {
    display: none !important;
  }
}

.showMobile {
  display: none;
  @include mq.mq($until: tablet) {
    display: block !important;
  }
}

.showFlexMobile {
  display: none;
  @include mq.mq($until: tablet) {
    display: flex !important;
  }
}

.vobi-submenu-popup {
  .fa-lock {
    position: absolute;
    right: 0;
    top: 32%;
    padding: 0 8px;
  }
  .ant-menu-item-selected {
    background-color: $color-white !important;
    ::after {
      opacity: 0;
    }
  }
  .ant-menu-item{
      span{
        font-size: $font-size-sm;
        color: $color-brand-primary-20;
      }
      text-align: center;
      &:hover {
        background-color: #{$color-brand-primary-70} + #{$opacity-02} !important;
        border-radius: $radius;
      }
    }
  .submenu-item-selected{
    background-color:  #{$color-brand-primary-70} + #{$opacity-02} !important;
    border-radius: $radius;
  }
}