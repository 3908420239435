@import "~@homehero/hero-style/dist/styles/settings/spaces";
@import "~@homehero/hero-style/dist/styles/tools/tools";
@import "../../../node_modules/@homehero/hero-style/dist/styles/tools/mq";

.c-add-project-button {
  &__search {
    display: none;
    position: absolute;
    left: 70%;
    bottom: 90%;
    z-index: 2;
    @include mq($until: tablet) {
      display: block;
      left: 65%;
    }
  }

  &__default {
    padding: $space-0 $space-2;
    z-index: 2;
  }

  &__detail {
    margin: $space-2 0;
    position: static;

    svg {
      margin-right: $space-1;
    }
  }
}
