@import "~@homehero/hero-style/dist/styles/settings/colors";
@import "~@homehero/hero-style/dist/styles/settings/radius";

:global {
  .tox-tinymce {
    border: solid 1px $color-neutral-60 !important;
    border-radius: $radius-2 !important;
  }

  .tox .tox-tbtn svg {
    fill: $color-neutral-40 !important;
  }

  .tox .tox-editor-header {
    z-index: unset;
  }

  .tox .tox-toolbar__primary {
    background: unset !important;
    border-bottom: solid 1px $color-neutral-60 !important;
  }
}
