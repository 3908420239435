@use "~@homehero/hero-style/dist/styles/settings/colors";
@use "~@homehero/hero-style/dist/styles/settings/spaces";
@use "~@homehero/hero-style/dist/styles/settings/types";
@import "~@homehero/hero-style/dist/styles/tools/mq";

.search-modal {
  height: 80vh;
  width: 80vw;
  border-radius: 8px;
}

.product-list {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: left;
}

.product {
  display: flex;
  margin: 8px 4px;
  flex-direction: column;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 15%;
  @include mq($until: tablet) {
    width: 45%;
  }
  min-width: 100px;
  transition: box-shadow 0.3s ease-out 0.1s, transform 0.3s ease-out 0.1s, opacity 0.2s ease-out 0.1s;
}
.product-center {
  align-self: center;
}

.product:hover {
  cursor: pointer;
  transform: scale(1.05);
  z-index: 9;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  transform: translate(0, -4px);
  button {
    display: inline-flex;
  }
  &::after {
    transform: scale(1);
    transform: translate(0, -4px);
  }
}

.filters {
  margin: spaces.$space-2;
  font-size: 14px;
}

.footer {
  flex-grow: 1;
  width: 100%;
  background-color: #fff;
  border-radius: 0 0 16px 16px;
  @include mq($until: tablet) {
    border-radius: 0;
  }
}
.modal-content {
  margin-bottom: 60px;
  min-height: 50%;
}
.modal-content-header {
  position: sticky;
  top: 0;
  z-index: 20;
  width: 100%;
  background-color: #fff;

  @include mq($until: tablet) {
    li {
      width: 100%;
      justify-content: center;
    }
  }
}
.modal-content-products {
  margin-top: calc(22vh - 2.5vw);
}

.error {
  display: flex;
  margin: auto;
  justify-content: center;
  align-content: center;
}

.cursor {
  cursor: pointer;
}

.product-new {
  position: fixed;
  margin: 0 spaces.$space-1;
  z-index: 10;
  bottom: 4%;
  right: 2%;
}

.product-new:hover {
  transform: scale(1.025);
}
.product-new button {
  background: none !important;
  color: colors.$color-brand-primary-40;
  font-weight: normal;
  font-size: types.$font-size-md;
  line-height: 18px;
  border: none;
  padding: 0 !important;
  cursor: pointer;
  font-family: 'muli', sans-serif;
}
