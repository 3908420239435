@import "~@homehero/hero-style/dist/styles/settings/spaces";
@import "~@homehero/hero-style/dist/styles/settings/colors";
@import "~@homehero/hero-style/dist/styles/settings/types";
@import "~@homehero/hero-style/dist/styles/tools/mq";

.filters {
  &_shrink-buttons {
    border: 0!important;
    padding-bottom: 0!important;
  }
  &_shrink-summary{
    margin-top: 0!important;
    @include mq($until: tablet) {
      button {
        font-size: 14px;
      }
    }
  }
  &__buttons {
    border-bottom: solid 1px $color-neutral-70;
    width: 100%;
    padding-bottom: $space-2;
  }

  &__tags,
  &__summary,
  &__pagiantion {
    display: flex;
    width: 100%;
  }

  &__tags {
    flex-wrap: wrap;
    flex: 1;

    @include mq($until: tablet) {
      margin-bottom: $space-1;
    }
  }

  &__summary {
    margin-top: $space-2;
    justify-content: space-between;
    align-items: flex-start;
    min-height: 36px;

    span + div {
      margin-left: $space-2;

      @include mq($until: tablet) {
        margin-left: 0;
        margin-top: $space-0;
      }
    }

    @include mq($until: tablet) {
      flex-direction: column;
    }
  }

  &__pagination {
    text-align: end;

    span {
      cursor: pointer;
    }
  }

  &__clear-all {
    border: none;
    background-color: transparent;
    padding: 0;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    font-size: $font-size-md;
    font-weight: 300;
    color: $color-primary;
    font-family: $font-family;
    margin-bottom: $space-1;
    margin-left: $space-1;

    svg {
      margin-right: $space-1;
    }
  }
}
